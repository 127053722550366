import '../css/Graph.css';
import GraphList from './GraphList.js';
import { useRef, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Graph() {
  const [data, setData] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dataId = queryParams.get('dataId');

  useEffect(() => {
    fetch('/dataItems.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('네트워크 응답이 올바르지 않습니다.');
        }
        return response.json();
      })
      .then(fetchedData => {
        // fetchedData의 각 항목(item)의 id와 쿼리 파라미터 dataId를 비교하여 필터링
        console.log(fetchedData);
        console.log('dataId:', dataId);
        const filteredData = fetchedData.filter(
          item => String(item.id) === dataId
        );
        console.log(filteredData);
        setData(filteredData[0]);
      })
      .catch(error => console.error('데이터를 불러오는 중 에러 발생:', error));
  }, [dataId]);

  return (
    <div id='graph'>
      {!data ? <div>...loading</div> :
        <>
          <div className='graphWrap'>
            <h2>{data.dataName}</h2>
            <div className='dataWrap'>
              <p>최근갱신일 <span>{data.updateData}</span></p>
              <p>조회수 <span>{data.view}</span></p>
            </div>
            <div className='categoryWrap'>
                <p><img src={process.env.PUBLIC_URL + "/img/circleMint.png"} />{data.region}</p>
              <div className="right_wrap">
                {(data.category && data.category.length > 0 )&& data.category.map((item, index) => (
                  <p key={index}><img src={process.env.PUBLIC_URL + "/img/circleMint.png"} />{item}</p>
                ))}
              </div>
            </div>
            <GraphList data={data}/>

          </div>
          <div className="cellContainer">
            <ul>
              <li>
                <p className='cellTitle'>생성날짜</p>
                <p className='cellInfo'>{data.createDate}</p>
              </li>
              <li>
                <p className='cellTitle'>최근 갱신일</p>
                <p className='cellInfo'>{data.updateData}</p>
              </li>
              <li>
                <p className='cellTitle'>유형</p>
                <p className='cellInfo'>{data.type}</p>
              </li>
              <li>
                <p className='cellTitle'>데이터 가공유형</p>
                <p className='cellInfo'>{data.dataProcessing}</p>
              </li>
              <li>
                <p className='cellTitle'>지역</p>
                <p className='cellInfo'>{data.region}</p>
              </li>
              <li>
                <p className='cellTitle'>카테고리</p>
                <p className='cellInfo'>{data.category}</p>
              </li>
              <li>
                <p className='cellTitle'>내용</p>
                <p className='cellInfo' style={{whiteSpace: 'pre-line'}}>
                  {data.description}
                </p>
              </li>
            </ul>
          </div>

        </>


      }
    </div>
  );
}

export default Graph;
