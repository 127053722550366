import '../css/DataProduct.css';
import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function DataProduct() {

  // 각 카테고리에 대한 상태 선언
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const itemsPerPage = 12; // 페이지당 아이템 수
  const [activeCategory, setActiveCategory] = useState(0);
  const [activeRegion, setActiveRegion] = useState(0);
  const [activeCondition, setActiveCondition] = useState(0);
  const [activeFormat, setActiveFormat] = useState(0);
  const [activeVisualType,setActiveVisualType] = useState(0);
  const [searchQuery, setSearchQuery] = useState(''); // 검색어 상태
  const [dataItems, setDataItems] = useState([]); // 데이터 아이템 상태
  const navigate = useNavigate(); // 페이지 이동을 위한 훅
  const location = useLocation(); // URL에서 쿼리 파라미터를 가져옴
  const queryParams = new URLSearchParams(location.search);

  // 공통적으로 사용할 클릭 핸들러
  const handleClick = (setStateFunction, index) => {
    setStateFunction(index);
  };


  const categories = ['전체', '경제,금융', '사회,과학', '산업', '주택,건설', '교통', '보건,의료', '사회복지', '교육', '환경,에너지', '문화,예술', '관광', '체육'];
  const regions = ['전체','전국', '서울', '경기도', '강원도', '충청도', '전라도', '경상도'];
  const visualType = ['전체','지도', '원', '선', '막대', '3D 지도'];
  const conditions = ['전체', '유료', '무료'];
  const formats = ['전체', '정형 데이터', '비정형 데이터'];
  const structuredFormats = ['JSON', 'CSV', 'XLSX']; // 정형 데이터 포맷
  const unstructuredFormats = ['JPEG', 'MP4', 'PNG']; // 비정형 데이터 포맷
  const visualTypeToCategory = ['null','map','pie','line','bar','3D'];
  const dataTypeToEnglish = {
    "지도" : "map",
    "원" : "pie",
    "선" : "line",
    "막대" : "bar",
    "3D 지도" : "3D"
  }

  // 컨텐츠 리스트
  const [activeSort, setActiveSort] = useState(0); // 정렬 옵션 상태
  const sortOptions = ['최신순', '다운로드순', '조회순'];

  const handleSortClick = (index) => setActiveSort(index);


  const categoryToVisualType = (category)=>{
    switch (category) {
        case 'map':
            return 1;
        case 'pie':
            return 2;
        case 'line':
            return 3;
        case 'bar':
            return 4;
        case '3D':
            return 5;
        
    }
  }
  const movePage = (id,visualType=null) => {
    console.log(visualType);
    console.log(dataTypeToEnglish[visualType]);
    if(visualType){
        navigate(`/graph?dataId=${id}&category=${dataTypeToEnglish[visualType]}`);
    }else{

        if(activeVisualType&& activeVisualType !== 0){
            navigate(`/graph?dataId=${id}&category=${visualTypeToCategory[activeVisualType]}`); // 이동할 경로 설정
    
        }else{
            navigate(`/graph?dataId=${id}`); // 이동할 경로 설정
    
        }
    }
  }



    // 필터링 로직
    const filteredItems = dataItems.filter((item) => {
        const selectedCategoryStr = categories[activeCategory];
        const selectedCategoryArray = selectedCategoryStr.split(',');
        const categoryMatch = activeCategory === 0 || selectedCategoryArray.some(cat => item.category.includes(cat.trim()));
        const regionMatch = activeRegion === 0 || item.region === regions[activeRegion];
        const conditionMatch = activeCondition === 0 || item.price === conditions[activeCondition];
        const formatMatch =
            activeFormat === 0 ||
            (activeFormat === 1 && structuredFormats.some((format) => item.type.includes(format))) ||
            (activeFormat === 2 && unstructuredFormats.some((format) => item.type.includes(format)));
        const visualTypeMatch =
            activeVisualType === 0 || // 전체 선택 시 모든 항목 포함
            (item.visualType && item.visualType.includes(visualType[activeVisualType]));
        const searchMatch = searchQuery === '' || Object.values(item).some((value) =>
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
    
        return categoryMatch && regionMatch && conditionMatch && formatMatch && visualTypeMatch && searchMatch;
    });
    


    // 현재 페이지에 표시될 데이터
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    // 페이지 수 계산
    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

    // 페이지 이동 핸들러
    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    useEffect(()=>{
        fetch('/dataItems.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('네트워크 응답이 올바르지 않습니다.');
                }
                return response.json();
            })
            .then(data => setDataItems(data))
            .catch(error => console.error('데이터를 불러오는 중 에러 발생:', error));
        
    },[]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const query = params.get('search') || '';
        setSearchQuery(query);
        const visualTypeIdParam = queryParams.get('visualTypeId') || '0'; 
        setActiveVisualType(Number(visualTypeIdParam));
    }, [location.search]);
    useEffect(() => {
        setCurrentPage(1);
      }, [activeCategory, activeRegion, activeCondition, activeFormat, activeVisualType, searchQuery]);
      

  return (
    <div id="product">
        <h2>데이터 상품</h2>
        <div className="actionWrap">
            <div className="searchWrap">
                <input
                    type="text"
                    placeholder="검색어를 입력해주세요"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)} // 검색어 상태 업데이트
                />
                <span className="searchIcon">
                    <img src={process.env.PUBLIC_URL + "/img/search_icon.png"} alt="검색" />
                </span>
            </div>
            <div className="categoryWrap">
                <p className="categoryTitle">데이터 마켓 상세검색</p>
                <ul className="categoryList">
                    {/* 카테고리 */}
                    <li>
                        <p className="listTitle">&middot; 카테고리</p>
                        <ul className="listContent">
                            {categories.map((item, index) => (
                                <li
                                    key={index}
                                    className={activeCategory === index ? 'active' : ''}
                                    onClick={() => handleClick(setActiveCategory, index)}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </li>

                    {/* 지역별 */}
                    <li>
                        <p className="listTitle">&middot; 지역별</p>
                        <ul className="listContent">
                            {regions.map((item, index) => (
                                <li
                                    key={index}
                                    className={activeRegion === index ? 'active' : ''}
                                    onClick={() => handleClick(setActiveRegion, index)}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </li>
                    {/* 시각화 유형 */}
                    <li>
                        <p className="listTitle">&middot; 시각화 유형</p>
                        <ul className="listContent">
                            {visualType.map((item, index) => (
                                <li
                                    key={index}
                                    className={activeVisualType === index ? 'active' : ''}
                                    onClick={() => handleClick(setActiveVisualType, index)}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </li>

                    {/* 이용조건 */}
                    <li>
                        <p className="listTitle">&middot; 이용조건</p>
                        <ul className="listContent">
                            {conditions.map((item, index) => (
                                <li
                                    key={index}
                                    className={activeCondition === index ? 'active' : ''}
                                    onClick={() => handleClick(setActiveCondition, index)}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </li>

                    {/* 파일포맷 */}
                    <li>
                        <p className="listTitle">&middot; 파일포맷</p>
                        <ul className="listContent">
                            {formats.map((item, index) => (
                                <li
                                    key={index}
                                    className={activeFormat === index ? 'active' : ''}
                                    onClick={() => handleClick(setActiveFormat, index)}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div className="displayWrap">
    <div className="cubeInfo">
        <ul>
            {currentItems.map((item, index) => (
                <li key={index} onClick={() => movePage(item.id,item.visualType[0])}>
                    <p className="infoImg">
                        <img src={`${process.env.PUBLIC_URL}${item.imageUrl}`} alt={item.title} />
                    </p>
                    <div className="textWrap">
                        <ul className="titleTxt">
                            <li>데이터명 | {item.title}</li>
                            <li>유형 | {item.type}</li>
                            <li>카테고리 | {item.category}</li>
                            <li>생성날짜 | {item.createDate}</li>
                        </ul>
                        <p className="infoTxt">
                            내용 -<br />
                            <span className="ellipsis">{item.description}</span>
                        </p>
                    </div>
                </li>
            ))}
        </ul>
        {/* Pagination */}
        <div className="page">
            <button
                className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                &lt;
            </button>
            <ul className="pagination">
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                    <li
                        key={page}
                        className={page === currentPage ? 'active' : ''}
                        onClick={() => handlePageChange(page)}
                    >
                        {page}
                    </li>
                ))}
            </ul>
            <button
                className={`next ${currentPage === totalPages ? 'disabled' : ''}`}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                &gt;
            </button>
        </div>
    </div>
</div>

    </div>
);
}

export default DataProduct;
