import { useRef, useEffect, useState } from 'react';
import { Chart, BarController, LineController, PieController, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Link, useLocation } from 'react-router-dom';
import Slider from 'react-slick'; // 슬라이드 컴포넌트 임포트
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Main from './Main';
import { ChoroplethController, ProjectionScale, GeoFeature, ColorScale,topojson } from 'chartjs-chart-geo'; // ColorScale 추가
import { feature } from 'topojson-client';

function GraphList(props) {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const sliderRef = useRef(null);
  // 그래프 타입을 관리하는 state
  const [chartType, setChartType] = useState('bar');
  const [iframeVisible, setIframeVisible] = useState(false); // 3D 모드 활성화 여부
  const [mapSize, setMapSize] = useState({ width: 0, height: 0 }); // width와 height 상태 관리
  const vmapRef = useRef(null);
  const [vmapVisible, setVmapVisible] = useState(true); // div 존재 여부 관리
  const isMobile = window.innerWidth <= 768; // 768px 이하를 모바일로 간주
  const isMobile2 = window.innerWidth <= 500; // 500px 이하를 모바일로 간주
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get('category') || 'bar'; // 기본값 'bar'
  const visualTypeMapping = {
    bar: "막대",
    line: "선",
    pie: "원",
    map: "지도",
    "3D": "3D 지도"
  };
  

  const images = [
    {
      id: 1,
      url: process.env.PUBLIC_URL + "/img/circleMint.png",
      value: 'bar',
      info: '막대'
    },
    {
      id: 2,
      url: process.env.PUBLIC_URL + "/img/circleMint.png",
      value: 'line',
      info: '선'
    },
    {
      id: 3,
      url: process.env.PUBLIC_URL + "/img/circleMint.png",
      value: 'pie',
      info: '원'
    },
    {
      id: 4,
      url: process.env.PUBLIC_URL + "/img/circleMint.png",
      value: 'map',
      info: '지도' // 지도 슬라이드 추가
    },
    {
      id: 5,
      url: process.env.PUBLIC_URL + "/img/circleMint.png",
      value: '3D',
      info: '3D' // 지도 슬라이드 추가
    }
  ];
  const filteredImages = images.filter(image => 
    props.data &&
    props.data.visualType &&
    props.data.visualType.includes(visualTypeMapping[image.value])
  );
  const handleChartTypeChange = (type) => {
      setChartType(type);
      setIframeVisible(type === '3D'); // 3D 선택 시 iframeVisible 활성화
  };


  // Chart.js에서 필요한 모듈 등록
  Chart.register(BarController, LineController, PieController, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, ArcElement, ChoroplethController, ProjectionScale, GeoFeature, ColorScale); // ColorScale 등록


  // 그래프를 렌더링하는 useEffect
  useEffect(() => {

    if (chartType === '3D' || iframeVisible) {
        return;
    }
    const ctx = chartRef.current.getContext('2d');

    if (chartInstance.current) {
      chartInstance.current.destroy(); // 기존 그래프 파괴
    }

    if (chartType === 'map') {
      const url = `${process.env.PUBLIC_URL}/mapData/korea_map_json.json`; // TopoJSON 파일 경로
      fetch(url)
          .then(response => response.json())
          .then((data) => {
            console.log(data);
            const updatedJson = addCountsToGeoJson(data,props.data.data);
            const nation = feature(updatedJson, updatedJson.objects.korea_map_json); // 전체 GeoJSON 객체
            const states = nation.features;
  
            console.log(nation);
            console.log(states);
      
            chartInstance.current = new Chart(ctx, {
              type: "choropleth",
              data: {
                  labels: states.map((d) => d.properties.CTP_KOR_NM),
                  datasets: [
                      {
                          label: "States",
                          outline: nation,
                          data: states.map((d) => ({
                              feature: d,
                              value: d.properties.count || Math.floor(Math.random() * 100) + 1,
                          })),
                      },
                  ],
              },
              options: {
                  showOutline: false,
                  showGraticule: false,
                  plugins: {
                      legend: {
                          display: false,
                      },
                  },
                  scales: {
                      projection: {
                          axis: "x",
                          projection: "mercator",
                          projectionScale: 1,
                          projectionOffset: [0, 0],
                          padding: 0,
                      },
                  },
                  layout: {
                      padding: {
                          right: 30 // 오른쪽 여백을 50px 추가하여 색상 막대가 잘리지 않도록 조정
                      }
                  }
              }
          });
          });
    } else if(chartType === '3D'){

    }else{
      console.log(props.data);
      const labels = Object.keys(props.data.data); // 시도 이름들
      const data = Object.values(props.data.data).map(sido => sido.count); // 각 시도의 count 수
      const dataName = props.data.dataName;
      chartInstance.current = new Chart(ctx, {
          type: chartType, // 'pie' 또는 'bar'로 설정
          data: {
              labels: labels,
              datasets: [
                  {
                      label: dataName,
                      data: data,
                      backgroundColor: chartType === 'pie' 
                          ? ['rgba(102, 204, 204, 0.8)', 'rgba(0, 102, 153, 0.8)', 'rgba(102, 102, 204, 0.8)', 'rgba(153, 102, 51, 0.8)', 'rgba(204, 102, 102, 0.8)', 'rgba(102, 204, 102, 0.8)', 'rgba(204, 204, 51, 0.8)', 'rgba(102, 153, 153, 0.8)', 'rgba(204, 51, 102, 0.8)', 'rgba(102, 51, 204, 0.8)', 'rgba(51, 153, 102, 0.8)', 'rgba(153, 51, 102, 0.8)', 'rgba(102, 102, 153, 0.8)', 'rgba(153, 102, 153, 0.8)', 'rgba(102, 102, 102, 0.8)', 'rgba(204, 102, 0, 0.8)', 'rgba(102, 204, 153, 0.8)'] 
                          : 'rgba(102, 204, 204, 0.8)', // bar 차트의 경우 한 가지 색상 사용
                      borderColor: 'rgba(102, 204, 204, 1)',
                      borderWidth: 1,
                      fill: chartType === 'line' ? false : undefined
                  }
              ]
          },
          options: {
              responsive: true,
              maintainAspectRatio: true,
              aspectRatio: isMobile ? 1 : 2,
              scales: chartType === 'pie' ? {} : { // pie 차트의 경우 축을 생략
                  y: {
                      beginAtZero: true,
                      title: {
                          display: true,
                      },
                      ticks: {
                          stepSize: 20000 // 단계는 필요에 따라 조정 가능
                      }
                  },
                  x: {
                      title: {
                          display: true,
                      }
                  }
              },
              plugins: {
                  legend: {
                      position: 'bottom',
                  },
                  tooltip: {
                      mode: 'index',
                      intersect: false,
                  }
              }
          }
      });
    }
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy(); // 메모리 누수 방지
      }
    };
  }, [chartType]);

  useEffect(() => {

    // sessionStorage에 새로고침 여부를 확인
    if(category){
      handleChartTypeChange(category)
    }

    const initializeMap = () => {
      console.log("window.viewer:", window.viewer);
      console.log("window.vw:", window.vw);
        // #vmap 컨테이너 확인
        const mapContainer = document.getElementById('vmap');
        if (!mapContainer) {
            console.error('#vmap 컨테이너가 존재하지 않습니다.');
            return;
        }

        // 기존 map 객체 재사용
        if (window.map) {
            console.log('기존 map 객체를 재사용합니다.');
            const options = {
                mapId: 'vmap',
                initPosition: new window.vw.CameraPosition(
                    new window.vw.CoordZ(props.data.coordinate.x,props.data.coordinate.y,props.data.coordinate.z), // 초기 위치 설정
                    new window.vw.Direction(60, -15, 0)
                ),
                logo: false,
                navigation: false,
            };
            window.map.setOption(options);
            window.map.start();
            return;
        }

        if (window.viewer) {
          console.log('기존 viewer 객체를 제거하고 다시 초기화합니다.');
          delete window.viewer; // 기존 전역 속성 삭제
      }
        // 새로운 map 객체 생성
        if (window.vw && window.vw.Map) {
            console.log('V-world API를 사용하여 지도를 초기화합니다.');
            const options = {
                mapId: 'vmap',
                initPosition: new window.vw.CameraPosition(
                    new window.vw.CoordZ(126.9784, 37.5665, 150), // 초기 위치 설정
                    new window.vw.Direction(60, -15, 0)
                ),
                logo: false,
                navigation: false,
            };

            window.map = new window.vw.Map();
            window.map.setOption(options);
            window.map.start();

            // 지도 이동 함수 추가
            window.map.vwmoveTo = (x, y, z) => {
                const movePo = new window.vw.CoordZ(x, y, z);
                const mPosi = new window.vw.CameraPosition(movePo, new window.vw.Direction(0, -80, 0));
                window.map.moveTo(mPosi);
            };
        } else {
            console.error('V-world API가 로드되지 않았습니다.');
        }
    };

    // 맵 크기 업데이트
    const updateMapSize = () => {
        const container = document.getElementById('vmap');
        if (container) {
            const width = container.offsetWidth;
            setMapSize({
                width,
                height: width / 2,
            });
        }
    };

    initializeMap();
    updateMapSize();
    window.addEventListener('resize', updateMapSize);

    return () => {
        window.removeEventListener('resize', updateMapSize);

        window.location.reload();
        // 지도 객체 초기화가 필요한 경우
    };
}, []);

  const addCountsToGeoJson = (geoJson, counts) => {
    // GeoJSON의 각 도(properties)에 시도별 count 추가
    const updatedFeatures = geoJson.objects.korea_map_json.geometries.map((feature) => {
        const sidoName = feature.properties.CTP_KOR_NM;
        if (counts[sidoName]) {
            feature.properties.count = counts[sidoName].count;
        }
        return feature;
    });

    // 업데이트된 GeoJSON 객체 반환
    return {
        ...geoJson,
        objects: {
            ...geoJson.objects,
            korea_map_json: {
                ...geoJson.objects.korea_map_json,
                geometries: updatedFeatures
            }
        }
    };
};
  // 슬라이드 설정
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: isMobile2 ? 2 : 4,
    slidesToScroll: 1,
  };



  return (
    <div id='graphList'>
      {!iframeVisible && <canvas id="myChart" ref={chartRef}></canvas>}
      {vmapVisible &&
        <div id="vmap" ref={vmapRef} style={{ 
            position: iframeVisible ? 'relative' : 'absolute',
            width: "100%", 
            height: `${mapSize.height}px`, // 동적으로 계산된 높이 적용
            left: "0px", 
            top: "0px",
            opacity: iframeVisible ? '1' : '0',
            pointerEvents: iframeVisible ? 'auto' : 'none',
            maxWidth:'1200px',
            margin:'5rem auto',

          }}>
        </div>
      }
      <div className='graphList'>
      <p className='listBtn' onClick={() => alert("다운로드 기능은 구현 예정입니다. ethan@spatialcore.ai 로 문의 바랍니다.")}>
        <button>
          다운로드
        </button>
      </p>
        <div className="slider-container">
          <Slider className='slider' ref={sliderRef} {...settings}>
            {filteredImages &&  filteredImages.map(image => (
              <div 
                className={`slide ${chartType === image.value ? 'active' : ''}`} // 선택된 그래프 타입일 경우 active 클래스 추가
                key={image.id} 
                onClick={() =>  handleChartTypeChange(image.value)}
              >
                <img src={image.url} alt={`Slide ${image.id}`} />
                <span>{image.info}</span>
              </div>
            ))}
          </Slider>
        
          <button className="prev" onClick={() => sliderRef.current.slickPrev()}>
            <img src={process.env.PUBLIC_URL + '/img/dropdown.png'} alt="prev" />
          </button>
          <button className="next" onClick={() => sliderRef.current.slickNext()}>
            <img src={process.env.PUBLIC_URL + '/img/dropdown.png'} alt="next" />
          </button>
        </div>
        <p className='listBtn'><Link to={Main}>목록보기</Link></p>
      </div>
    </div>
  );
}

export default GraphList;




//   const fetchCsvData = async () => {
//       try {
//           const response = await fetch(`${process.env.PUBLIC_URL}/data/building/POI_3d_web_CA01_2024.csv`);
//           const csvData = await response.text();
//           const parsedData = parseCSV(csvData);
//           const groupedData = groupBySido(parsedData);
//           console.log("Parsed Data:", parsedData);
//           console.log(groupedData);
//       } catch (error) {
//           console.error("Error fetching the CSV file:", error);
//       }
//   };

//   const fetchMultipleCsvData = async () => {
//     try {
//         // 3개의 CSV 파일을 비동기적으로 읽어옵니다.
//         const responses = await Promise.all([
//             fetch(`${process.env.PUBLIC_URL}/data/building/POI_3d_web_CA01_2024.csv`),
//             fetch(`${process.env.PUBLIC_URL}/data/building/POI_3d_web_CA01_2024.csv`),
//             fetch(`${process.env.PUBLIC_URL}/data/building/POI_3d_web_CA01_2024.csv`),
//         ]);

//         const csvTexts = await Promise.all(responses.map((response) => response.text()));

//         // 각 CSV 파일의 데이터를 파싱하여 하나의 배열로 합칩니다.
//         const allData = csvTexts.flatMap((csvText) => parseCSV(csvText));

//         // 합친 데이터를 sido_nm으로 그룹화합니다.
//         const groupedData = groupBySido(allData);
//         console.log("Grouped Data by Sido:", groupedData);
//     } catch (error) {
//         console.error("Error fetching the CSV files:", error);
//     }
// };

//   const parseCSV = (csvData) => {
//     const rows = csvData.split('\n');
//     const headers = rows[0].replace('\r', '').split(','); // 첫 번째 행을 헤더로 사용

//     const data = rows.slice(1).map((row) => {
//         const values = row.replace('\r', '').split(','); // 각 행의 데이터를 배열로 변환
//         const obj = headers.reduce((acc, header, index) => {
//             acc[header.trim()] = values[index]?.trim(); // 각 헤더와 값을 객체의 키와 값으로 매핑
//             return acc;
//         }, {});
//         return obj;
//     });

//     return data;
// };

// const groupBySido = (data) => {
//     return data.reduce((acc, item) => {
//         const sido = item.sido_nm;
//         if (sido) {
//             if (!acc[sido]) {
//                 acc[sido] = { count: 0, items: [] };
//             }
//             acc[sido].count += 1;
//             acc[sido].items.push(item);
//         }
//         return acc;
//     }, {});
// };

